import { Box, SxProps, Theme } from "@mui/material"
import Link from "next/link"
import { PropsWithChildren } from "react"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"

interface Props extends PropsWithChildren {
    href: string
    target?: string
    sx?: SxProps<Theme>
}

export const PlainLink = ({ href, target = "_self", sx = {}, children, ...props }: Props) => {
    const combinedStyle = {
        ...style,
        ...sx,
    }

    const isExternal = target === "_blank"

    return (
        <Link {...props} href={href} target={target} style={{ textDecoration: "none" }}>
            <Box sx={combinedStyle}>
                {children}
                {isExternal && (
                    <Box component="span" sx={{ fontSize: "0.7rem" }}>
                        ↗
                    </Box>
                )}
            </Box>
        </Link>
    )
}

const style: SxProps<Theme> = {
    display: "flex",
    alignItems: "center",
    gap: 1,
    fontWeight: 500,
    color: "rgba(255, 255, 255, 0.7)",
    transition: "color 0.2s",
    ":hover": {
        color: "text.primary",
    },
}
