"use client"

import { getTheme } from "@/theme"
import { Box, Button, ButtonProps, SxProps, Theme } from "@mui/material"
import Link from "next/link"
import { PropsWithChildren } from "react"

interface Props extends PropsWithChildren<ButtonProps> {
    href: string
    target?: string
    sx?: SxProps<Theme>
}

export const LinkButton = ({ href, target, children, sx = {}, ...props }: Props) => {
    const style = {
        ...styles.button,
        ...sx,
        ...(props.variant === "outlined" ? styles.outlinedButton : {}),
    }

    return (
        <Link target={target} href={href} style={{ textDecoration: "none" }}>
            <Button {...props} sx={style}>
                {children}
                {target == "_blank" && (
                    <Box component="span" sx={{ fontSize: "0.8rem" }}>
                        &nbsp; ↗
                    </Box>
                )}
            </Button>
        </Link>
    )
}

const theme = getTheme()

const styles: Record<string, SxProps<Theme>> = {
    button: {
        color: theme.palette.text.primary,
        borderColor: theme.palette.common.white,
        fontSize: theme.typography.body1,
        fontWeight: 500,
        justifyContent: "center",
        borderRadius: 4,
        borderWidth: 2,
        px: 2,
        py: 0.5,
        ":hover": {
            color: theme.palette.text.primary,
            borderColor: theme.palette.text.secondary,
            borderBlockEndWidth: 2,
            backgroundColor: "rgba(255, 255, 255, 0.15)",
        },
    },
    outlinedButton: {
        ":hover": {
            borderColor: "transparent",
            backgroundColor: theme.palette.primary.dark,
            borderWidth: 2,
        },
    },
}
