import { alpha, createTheme } from "@mui/material/styles"
import { ThemeOptions } from "@mui/material/styles/createTheme"

declare module "@mui/material/styles/createTypography" {
    interface TypographyOptions {
        secondaryFontFamily: any
    }
    interface Typography {
        secondaryFontFamily: any
    }
}
declare module "@mui/material/styles/createPalette" {
    export interface TypeBackground {
        default: string
        paper: string
        layout: string
    }
}
declare module "@mui/material/styles/createPalette" {
    export interface TypeBackground {
        default: string
        paper: string
        layout: string
    }
}

declare module "@mui/material/styles" {
    interface Theme {
        borders: {
            paper: string
        }
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        borders?: {
            paper?: string
        }
    }
}

export const CONTENT_SCRIPT_Z_INDEX = 9999999999
export const SUBTLE_BACKGROUND_GLOW =
    "radial-gradient(70.71% 70.71% at 50% 50%, rgba(102, 99, 246, 0.07) 0%, rgba(255, 255, 255, 0.00) 100%)"

const getTheme = (options: Partial<ThemeOptions> = {}) => {
    let theme = createTheme({
        palette: {
            mode: "dark",
            primary: {
                main: "#455EB5",
                dark: "#455EB5CC",
            },
            secondary: {
                light: "#62c6f5",
                main: "#0ea5e9",
                dark: "#0ea5e9AA",
            },
            error: {
                main: "#d36e65",
            },
            success: {
                main: "#6bac65",
            },
            background: {
                paper: "#1A1531",
                default: "#000212",
                layout: "#000212",
            },
            action: {
                active: "rgba(255, 255, 255, 0.6)",
                disabled: "rgba(255, 255, 255, 0.3)",
                selected: "rgba(255, 255, 255, 0.16)",
                disabledBackground: "rgba(255, 255, 255, 0.12)",
                hover: "rgba(255, 255, 255, 0.08)",
            },
            text: {
                primary: "rgba(255,255,255,0.9)",
                secondary: "rgba(255,255,255,0.85)",
            },
        },
        shape: {
            borderRadius: 16,
        },
        typography: {
            fontFamily: ["Montserrat", "sans-serif"].join(","),
            secondaryFontFamily: ["Roboto", "Helvetica", "sans-serif", "Arial"].join(","),
            button: {
                textTransform: "none",
            },
            ...options.typography,
        },
    })

    theme.borders = {
        paper: `1px solid ${theme.palette.action.disabled}`,
    }

    theme.components = {
        MuiMenu: {
            styleOverrides: {
                list: {
                    backgroundColor: theme.palette.background.layout,
                },
                paper: {
                    border: theme.borders.paper,
                },
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                containedInherit: {
                    color: theme.palette.text.secondary,
                    backgroundColor: theme.palette.action.disabledBackground,
                    boxShadow: "none",
                    "&:hover": {
                        color: theme.palette.text.primary,
                        backgroundColor: theme.palette.action.disabled,
                        boxShadow: "none",
                    },
                },
            },
        },
        MuiSkeleton: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.action.disabledBackground,
                    borderRadius: theme.shape.borderRadius,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.primary,
                    borderColor: theme.palette.action.disabled,
                    "&.MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.palette.action.disabled,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.palette.action.disabled,
                        borderWidth: 1,
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.primary,
                    "&.Mui-focused": {
                        color: theme.palette.text.primary,
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundImage: "none",
                },
            },
        },
        MuiTooltip: {
            defaultProps: {
                arrow: true,
                enterTouchDelay: 200,
                leaveTouchDelay: 100,
                enterDelay: 500,
                enterNextDelay: 500,
            },
            styleOverrides: {
                tooltip: {
                    borderRadius: theme.spacing(1),
                    color: theme.palette.background.default,
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    overflowX: "hidden",
                },
                "*::-webkit-scrollbar": {
                    width: 8,
                },
                "*::-webkit-scrollbar-track": {
                    backgroundColor: theme.palette.background.layout,
                    borderRadius: theme.shape.borderRadius,
                },
                "*::-webkit-scrollbar-thumb": {
                    backgroundColor: theme.palette.text.secondary,
                    borderRadius: theme.shape.borderRadius,
                },
                "::selection": {
                    backgroundColor: alpha("#B4D5FE", 0.7),
                },
            },
        },
    }

    return theme
}

export { getTheme }
