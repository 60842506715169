"use client"

import { ROUTES } from "@/constants/routes"
import { Close, Menu } from "@mui/icons-material"
import { Box, Grow, IconButton, Paper, SxProps, Theme } from "@mui/material"
import { useState } from "react"
import { ActionButton } from "../buttons/ActionButton"
import { LinkButton } from "../buttons/LinkButton"
import Logo from "./Logo"
import { pages } from "./pages"

export const MobileMenu = () => {
    const [isOpen, setIsOpen] = useState(false)

    const handleOpen = () => {
        document.body.style.overflow = "hidden"
        setIsOpen(true)
    }

    const handleClose = () => {
        document.body.style.overflow = "auto"
        setIsOpen(false)
    }

    return (
        <>
            <IconButton aria-label="Menu button" onClick={handleOpen}>
                <Menu sx={{ fill: "white" }} />
            </IconButton>
            {isOpen && (
                <Grow in timeout={250}>
                    <Paper sx={styles.menu}>
                        <Logo sx={styles.logo} size="lg" />
                        <IconButton
                            aria-label="Close menu button"
                            sx={styles.close}
                            onClick={handleClose}
                        >
                            <Close fontSize="large" />
                        </IconButton>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            sx={styles.links}
                        >
                            {pages.map((page) => (
                                <LinkButton
                                    sx={styles.link}
                                    key={page.url}
                                    href={page.url}
                                    onClick={handleClose}
                                    aria-label={`${page.title} link button`}
                                >
                                    {page.title}
                                </LinkButton>
                            ))}
                        </Box>
                        <Box sx={styles.cta}>
                            <ActionButton
                                href={ROUTES.LOGIN}
                                sx={{ minWidth: 120 }}
                                aria-label="Login link button"
                            >
                                Log in
                            </ActionButton>
                            <ActionButton
                                href={ROUTES.SIGNUP}
                                sx={{ minWidth: 120 }}
                                aria-label="Signup link button"
                            >
                                Sign up
                            </ActionButton>
                        </Box>
                    </Paper>
                </Grow>
            )}
        </>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    menu: {
        height: "100vh",
        width: "100vw",
        position: "fixed",
        top: 0,
        left: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: (theme) => theme.palette.background.default,
        borderRadius: "0 !important",
    },
    logo: {
        position: "absolute",
        top: "15%",
        left: "50%",
        transform: "translateX(-50%)",
    },
    close: {
        position: "absolute",
        right: 20,
        top: 20,
        color: "white",
    },
    links: {
        position: "absolute",
        top: "40%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    link: {
        px: 2,
        fontSize: (theme) => theme.typography.h6.fontSize,
    },
    cta: {
        position: "absolute",
        bottom: "25%",
        left: "50%",
        transform: "translateX(-50%)",
        display: "flex",
        gap: 2,
        flexDirection: "column",
    },
}
