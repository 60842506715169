import { ServiceAccount } from "firebase-admin"

export const serviceAccount: ServiceAccount = {
    projectId: process.env.FIREBASE_PROJECT_ID,
    privateKey: process.env.FIREBASE_PRIVATE_KEY,
    clientEmail: process.env.FIREBASE_CLIENT_EMAIL,
}

export const posthogConfig = {
    key: process.env.NEXT_PUBLIC_POSTHOG_KEY as string,
    host: process.env.NEXT_PUBLIC_POSTHOG_HOST as string,
}

export const host = "https://apollo.getrecall.ai"

export const SUPPORT_EMAIL = "support@getrecall.ai"
