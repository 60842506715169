"use client"

import { useEffect } from "react"

export const ReferralCookie = () => {
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const referralToken = urlParams.get("token")

        const currentDate = new Date()
        currentDate.setMonth(currentDate.getMonth() + 1)

        if (referralToken) {
            document.cookie = `referral_token=${referralToken}; domain=.getrecall.ai; path=/; expires=${currentDate.toUTCString()}; Secure`
        }
    }, [])

    return null
}
