import { ROUTES } from "@/constants/routes"

export const pages = [
    {
        title: "Home",
        url: ROUTES.HOME,
    },
    {
        title: "Blog",
        url: ROUTES.BLOG,
    },
    {
        title: "Pricing",
        url: ROUTES.PRICING,
    },

    {
        title: "Summaries",
        url: ROUTES.SUMMARIES,
    },
    {
        title: "Docs",
        url: ROUTES.DOCS,
        target: "_blank",
    },
]
