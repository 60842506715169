"use client"
import { posthogConfig } from "@/constants/config"
import posthog from "posthog-js"
import { PostHogProvider } from "posthog-js/react"

if (typeof window !== "undefined" && process.env.NODE_ENV !== "development") {
    posthog.init(posthogConfig.key, {
        api_host: posthogConfig.host,
        disable_session_recording: true,
        autocapture: false,
        capture_pageview: true,
    })
}

export function PHProvider({ children }: { children: React.ReactNode }) {
    // @ts-ignore
    return <PostHogProvider client={posthog}>{children}</PostHogProvider>
}
